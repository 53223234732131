import React from 'react'

const WhyChooseUs = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: 600 }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
        <h1 className="mb-0">
        We Ensure Infinite Growth of Business 
        </h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-4">
          <div className="row g-5">
            <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-cubes text-white" />
              </div>
              <h4>Innovative Implementation  </h4>
              <p className="mb-0">
              Our professionals focus on in-depth research to integrate optimized designs & functionalities into viable solutions. In the competitive business arena, our innovative technological approaches ensure the long-term business growth of our clients.
              </p>
            </div>
            <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-award text-white" />
              </div>
              <h4>	Real-Time Supports</h4>
              <p className="mb-0">
              In this digital era, we respect the valuable time of our clients. So, we are dedicated to providing real-time support to our clients so they can keep their operations intact during business hours.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4  wow zoomIn"
          data-wow-delay="0.9s"
          style={{ minHeight: 350 }}
        >
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.1s"
              src="/static/img/feature.jpg"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row g-5">
            <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-users-cog text-white" />
              </div>
              <h4>	Cost-Effective Solutions</h4>
              <p className="mb-0">
              Our expert team is efficient enough to implement cost-effective, interactive features into the client’s corporate websites or business apps. So, we never disappoint our clients in converting their business concepts into engaging business solutions within their budgets.
              </p>
            </div>
            <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-phone-alt text-white" />
              </div>
              <h4>	Custom Development</h4>
              <p className="mb-0">
              Every industry has specific networking protocols, which should be reflected in the business websites. Our decade-long experience as a leading IT provider pushes our team to approach clients to create ROI-centric versatile business solutions to win the races in worldwide digital spaces.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default WhyChooseUs