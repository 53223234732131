import "./App.css";
import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import PortfolioPage from "./pages/PortfolioPage"
import ContactPage from "./pages/ContactPage"
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<> 
            <HomePage />
          </>} />
          <Route path="/about" element={<> 
            <AboutPage />
          </>} />
            
          <Route path="/portfolio" element={<>
                <PortfolioPage />
          </>} />

          <Route path="/contact" element={<>
            <ContactPage />
          </>} />

        </Routes>
      </BrowserRouter>
      
    </>
  );
}

export default App;
