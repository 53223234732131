import React from 'react'
import TopNavbar from '../components/TopNavbar';
import Navbar from '../components/Navbar';
import About from '../components/About';
import WhyChooseUs from '../components/WhyChooseUs';
import Services from '../components/Services';
import Price from '../components/Price';
import QuoteStart from '../components/QuoteStart';
import Footer from '../components/Footer';
import Facts from '../components/Facts';
import WebImg from '../components/WebImg';

const HomePage = () => {
  return (
    <>
     {/* <TopNavbar/> */}
     <Navbar/>
     <Facts/>
     <About/>
     <WhyChooseUs/>
     <Services/>
     <Price/>
     <QuoteStart/>
     <WebImg/>
     <Footer/>
    </>
  )
}

export default HomePage