import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo (1).svg";

const Navbar = () => {
  return (
    <>
      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
          <Link to="/" className="navbar-brand p-0">
            <img src={logo} style={{ width: "12rem" }} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className="nav-item nav-link active">
                Home
              </Link>
              <Link to="/about" className="nav-item nav-link">
                About
              </Link>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Services
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="price.html" className="dropdown-item">
                    Web Design
                  </Link>
                  <Link to="feature.html" className="dropdown-item">
                    Data Analytics
                  </Link>
                  <Link to="team.html" className="dropdown-item">
                    Web Development
                  </Link>
                  <Link to="" className="dropdown-item">
                    Apps Development
                  </Link>
                  <Link to="" className="dropdown-item">
                    SEO Optimization
                  </Link>
                </div>
              </div>
              <Link to="/portfolio" className="nav-item nav-link">
                Portfolio
              </Link>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Technologies
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="blog.html" className="dropdown-item">
                    React JS
                  </Link>
                  <Link to="" className="dropdown-item">
                    Next JS
                  </Link>
                  <Link to="" className="dropdown-item">
                    React Native
                  </Link>
                  <Link to="" className="dropdown-item">
                    Python Development
                  </Link>
                  <Link to="" className="dropdown-item">
                    Java Development
                  </Link>
                </div>
              </div>

              <Link to="/contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            {/* <Link to="" className="btn btn-primary py-2 px-4 ms-3">
              +1 (321) 710 8967
            </Link> */}
          </div>
        </nav>
        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="w-100"
                src="/static/img/carousel-1.jpg"
                alt="Image"
              />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: 900 }}>
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Creative &amp; Innovative
                  </h5>
                  <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                    Creative &amp; Innovative Digital Solution
                  </h1>
                  <Link
                    to="quote.html"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Free Quote
                  </Link>
                  <Link
                    to=""
                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item" style={{ height: "700px" }}>
              <img
                className="w-100"
                src="/static/img/carousel-2.jpg"
                alt="Image"
              />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: 900 }}>
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Creative &amp; Innovative
                  </h5>
                  <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                    Creative &amp; Innovative Digital Solution
                  </h1>
                  <Link
                    to="quote.html"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Free Quote
                  </Link>
                  <Link
                    to=""
                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
