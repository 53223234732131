import React from "react";
import TopNavbar from "../components/TopNavbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const PortfolioPage = () => {
  return <div>
    <TopNavbar/>
    {/* Navbar Start */}
    <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
          <Link to="index.html" className="navbar-brand p-0">
            <h1 className="m-0">
              <i className="fa fa-user-tie me-2" />
              Webjob
            </h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className="nav-item nav-link active">
                Home
              </Link>
              <Link to="/about" className="nav-item nav-link">
                About
              </Link>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Services
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="price.html" className="dropdown-item">
                    Web Design
                  </Link>
                  <Link to="feature.html" className="dropdown-item">
                    Data Analytics
                  </Link>
                  <Link to="team.html" className="dropdown-item">
                    Web Development
                  </Link>
                  <Link to="" className="dropdown-item">
                    Apps Development
                  </Link>
                  <Link to="" className="dropdown-item">
                    SEO Optimization
                  </Link>
                </div>
              </div>
              <Link to="/portfolio" className="nav-item nav-link active">
                Portfolio
              </Link>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Technologies
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="blog.html" className="dropdown-item">
                    React JS
                  </Link>
                  <Link to="" className="dropdown-item">
                    Next JS
                  </Link>
                  <Link to="" className="dropdown-item">
                    React Native
                  </Link>
                  <Link to="" className="dropdown-item">
                    Python Development
                  </Link>
                  <Link to="" className="dropdown-item">
                    Java Development
                  </Link>
                </div>
              </div>

              <Link to="/contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Link to="" className="btn btn-primary py-2 px-4 ms-3">
              +1 (321) 710 8967
            </Link>
          </div>
        </nav>
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: 90 }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Portfolio</h1>
              <Link to="/" className="h5 text-white">
                Home
              </Link>
              <i className="far fa-circle text-white px-2" />
              <Link to="" className="h5 text-white">
                Portfolio
              </Link>
            </div>
          </div>
        </div> 
      </div>
      {/* Navbar End */}
    <Footer/>
  </div>;
};

export default PortfolioPage;
