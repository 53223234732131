import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <Link to="/" className="navbar-brand">
                  <h1 className="m-0 text-white">
                    <i className="fa fa-user-tie me-2" />
                    Startup
                  </h1>
                </Link>
                <p className="mt-3 mb-4">
                  We are one of the leading IT services providers who utilize
                  the latest verticals of technologies so our clients can easily
                  win business challenges. Our professionals ensure our client's
                  business growth beyond the flaws of fluctuating worldwide
                  markets.
                </p>
                <form action="">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-white p-3"
                      placeholder="Your Email"
                    />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2" />
                    <p className="mb-0">20417 Hillside Ave, Hollis, NY 11423</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2" />
                    <p className="mb-0">info@webjobtechnologies.com</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2" />
                    <p className="mb-0">+1 (321) 710 8967</p>
                  </div>
                  <div className="d-flex mt-4">
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://twitter.com/qamar_soft?lang=en"
                    >
                      <i className="fab fa-twitter fw-normal" />
                    </Link>
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://www.facebook.com/qamarsoftsolutions"
                    >
                      <i className="fab fa-facebook-f fw-normal" />
                    </Link>
                    <Link
                      className="btn btn-primary btn-square me-2"
                      to="https://twitter.com/qamar_soft?lang=en"
                    >
                      <i className="fab fa-linkedin-in fw-normal" />
                    </Link>
                    <Link
                      className="btn btn-primary btn-square"
                      to="https://www.instagram.com/qamarsoftsolutions/"
                    >
                      <i className="fab fa-instagram fw-normal" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light mb-2" to="/">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Home
                    </Link>
                    <Link className="text-light mb-2" to="/about">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      About Us
                    </Link>
                    <Link className="text-light mb-2" to="">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Services
                    </Link>
                    <Link className="text-light mb-2" to="/portfolio">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Portfolio
                    </Link>
                    <Link className="text-light mb-2" to="">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Technologies
                    </Link>
                    <Link className="text-light" to="/contact">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Technologies</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light mb-2" to="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      React JS
                    </Link>
                    <Link className="text-light mb-2" to="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Next JS
                    </Link>
                    <Link className="text-light mb-2" to="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      React Native
                    </Link>
                    <Link className="text-light mb-2" to="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Python Development
                    </Link>
                    <Link className="text-light mb-2" to="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Java Development
                    </Link>
                    <Link className="text-light" to="#">
                      <i className="bi bi-arrow-right text-primary me-2" />
                      Dot Net Development
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: 75 }}
              >
                <p className="mb-0">
                  ©{" "}
                  <Link
                    className="text-white border-bottom"
                    to="https://webjobtechnologies.com/"
                  >
                    webjobtechnologies
                  </Link>
                  . All Rights Reserved. Designed by
                  <Link className="text-white border-bottom" to=""></Link>
                </p>
                <br />

                <Link className="border-bottom" to="" target="_blank"></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
};

export default Footer;
