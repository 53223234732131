import React from "react";
import TopNavbar from "../components/TopNavbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <>
      <TopNavbar />
      {/* Navbar Start */}
      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
          <Link to="index.html" className="navbar-brand p-0">
            <h1 className="m-0">
              <i className="fa fa-user-tie me-2" />
              Webjob
            </h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className="nav-item nav-link ">
                Home
              </Link>
              <Link to="/about" className="nav-item nav-link active">
                About
              </Link>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Services
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="price.html" className="dropdown-item">
                    Web Design
                  </Link>
                  <Link to="feature.html" className="dropdown-item">
                    Data Analytics
                  </Link>
                  <Link to="team.html" className="dropdown-item">
                    Web Development
                  </Link>
                  <Link to="" className="dropdown-item">
                    Apps Development
                  </Link>
                  <Link to="" className="dropdown-item">
                    SEO Optimization
                  </Link>
                </div>
              </div>
              <Link to="/portfolio" className="nav-item nav-link">
                Portfolio
              </Link>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Technologies
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="blog.html" className="dropdown-item">
                    React JS
                  </Link>
                  <Link to="" className="dropdown-item">
                    Next JS
                  </Link>
                  <Link to="" className="dropdown-item">
                    React Native
                  </Link>
                  <Link to="" className="dropdown-item">
                    Python Development
                  </Link>
                  <Link to="" className="dropdown-item">
                    Java Development
                  </Link>
                </div>
              </div>

              <Link to="/contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Link to="" className="btn btn-primary py-2 px-4 ms-3">
              +1 (321) 710 8967
            </Link>
          </div>
        </nav>
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: 90 }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">About Us</h1>
              <Link to="/" className="h5 text-white">
                Home
              </Link>
              <i className="far fa-circle text-white px-2" />
              <Link to="" className="h5 text-white">
                About
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar End */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  About Us
                </h5>
              </div>
              <p className="mb-4">
                From the beginning of its journey as the best IT service
                provider firm, Webjob Technologies Pvt Ltd has maintained a
                glorious track record of timely delivery, utmost quality,
                innovative implementation, use of agile technologies, prompt
                communications with clients, and so much more. We are the
                leading IT solution that can support recent evolving business
                growth. <br />
                <b>We have:</b>
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" /> Top-notch
                    Infrastructure
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" /> Authentic
                    Network
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" /> Commercial
                    Ethics
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" /> Customized
                    Development Package
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" />
                    Expertise in Next-gen Technologies
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-5" style={{ minHeight: 500 }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src="/static/img/about.jpg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  Our Vision
                </h5>
              </div>
              <p className="">
                We highly focus on customized development projects to satisfy
                our clients with ROI-driven business solutions. Our expert
                software professionals recommend our clients' real-time
                solutions as they can benefit from implemented viable features.
                We never hesitate to ensure flexibility in the developed
                applications as they can run on different platforms to enhance
                the accessibility of users or consumers.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  Our Mission
                </h5>
              </div>
              <p className="">
                We accelerate different business verticals with customized &
                ROI-centric IT solutions. Our professionals always ensure
                hardcore data security in our delivered applications. Our
                clients benefit from user-friendly IT solutions as we implement
                research-based interactive features in business applications.
                Our expertise in data analysis ensures that our delivered
                applications can engage users with a high range of
                accessibility, technological compatibility, and ideal
                representation of essential business information.
              </p>
            </div>

            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  Our Speciality
                </h5>
              </div>
              <p className="mb-4">
                As a top-tier IT solution provider, Webjob Technologies Pvt Ltd
                has a dedicated & professional team that executes the core
                branding methodology of the client's business. We take care of
                technology-driven business aptitudes in different development
                projects for web and mobile applications. Our expert team always
                focuses on providing the following facilities in customized
                development packages:
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" />Responsive Web Design
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" />Interactive Graphic Elements
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" />Prototype-Based App Development
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" />Latest Data Security Features
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" /> Fully-Optimized E-commerce Solutions 
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3" />
                    Next-gen Technology Implementation
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  What We Offer As The Best IT Solutions?
                </h5>
              </div>
              <p className="">
                We always offer the leading, highly customizable, user-engaging,
                device-compatible, next-gen IT solutions. Our professional
                designers & software developers are highly responsible for
                integrating the latest technologies, viable coding, optimized
                designs, and innovative implementations of ROI-centric features,
                ensuring flawless business operations, networking, and growth.
                Therefore, our clients can build resourceful online business
                infrastructure with our solutions to maximize their business
                revenue step by step. Moreover, our commercial ethics drive our
                team to deliver best-in-class business applications with
                top-notch security in time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
